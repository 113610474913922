export const APIs = {
  MAIN_URL: import.meta.env.REACT_APP_API_URL,
  UPLOAD_SERVICE_URL: import.meta.env.REACT_APP_API_UPLOAD_URL,
  LOG_SERVER_URL: import.meta.env.REACT_APP_LOG_API_URL,
};

const urls = {
  //? customers (ANOTHER API)
  customerPayoutList: '/admin/customer/',

  //? Signed docs
  signedDocuments: '/admin/customer/docs/signed',
  invoices: '/admin/invoices',
  downloadInvoices: '/admin/invoices/download',
  downloadPrepaidCardInfo: 'admin/prepaid-card-balances/download',

  //? Auth
  signIn: '/admin-auth/sign-in',
  refreshToken: '/admin-auth/refresh-tokens',
  forgotPassword: '/admin-auth/forgot-password',
  resetPassword: '/admin-auth/reset-password',
  emailVerify: '/user/email-verify',

  //? Other
  products: '/admin/products',
  services: 'shop/services',
  primaryService: 'shop/available-primary-services',
  instances: '/region/list',
  countries: '/region/country/list',
  orderList: '/admin/order/list',
  order: '/admin/order/{id}',
  failedList: '/admin/order/failed-list',
  uploadFailedOrder: 'admin/order/failed/pay-outside',
  refundOrder: '/admin/order/id/refund',

  countriesWithoutInstances: '/region/country/without-instances',
  instanceWebsites: '/admin/instance/websites',
  skuList: '/admin/sku/list',
  twillioSettings: '/admin/messenger/twilio',

  syncServices: '/admin/third-party/synchronize',

  //? Admins
  adminList: '/admin/operator/list',
  createAdmin: '/admin/operator/create',
  editAdmin: '/admin/operator/',
  deleteAdmin: '/admin/operator/',
  deactivateAdmin: '/admin/operator/',
  massPayouts: '/admin/mass-payout',

  //? Legal Documents
  legalDocuments: '/admin/legal-documents',
  legalDocument: '/admin/legal-document',

  //? Products
  productList: '/admin/product/list',
  createProduct: '/admin/product/create',
  editProduct: '/admin/product/',
  deleteProduct: '/admin/product/',
  baseProduct: '/admin/product/',
  createUpgradeRule: '/admin/plan-upgrade-rules/create',
  upgradeRulesBase: '/admin/plan-upgrade-rules/',
  comboBase: '/admin/combo/',
  comboProduct: '/admin/combo/product/',
  plan: '/admin/plan/',
  nestedRules: '/admin/nested-rules/',
  productCategoriesList: '/admin/product-category',
  editProductCategoriesList: '/admin/product-category/',
  deleteProductCategories: '/admin/product-category/',
  planMissedCountries: '/admin/plan-group/{id}/missed-countries',

  createPurchaseRule: '/admin/plan/{id}/purchase-rule',
  deletePurchaseRule: '/admin/plan/purchase-rule/{id}',
  createRequiredPlan: '/admin/plan/purchase-rule/{id}/required-plan',
  createRequiredService: '/admin/plan/purchase-rule/{id}/required-service',
  updateRequiredPlan: '/admin/plan/purchase-rule/required-plan/{id}',
  deleteRequiredPlan: '/admin/plan/purchase-rule/required-plan/{id}',
  readPurchaseRules: '/admin/plan/{id}/purchase-rules',

  readUpsellingRules: '/admin/upselling-rules',
  editUpsellingRules: '/admin/upselling-rules/',
  deleteUpsellingRules: '/admin/upselling-rules/',
  createUpsellingRules: '/admin/upselling-rules',

  readSwitchingRules: '/admin/switching-rules',
  editSwitchingRules: '/admin/switching-rules/',
  deleteSwitchingRules: '/admin/switching-rules/',
  createSwitchingRules: '/admin/switching-rules',

  changePlanStatus: '/admin/plan/{id}/status',
  changeProductStatus: '/admin/product/{id}/status',
  changeComboPlanStatus: '/admin/combo-info/{id}/status',
  changeComboProductStatus: '/admin/combo-product/{id}/status',

  //? Instances / Countries
  instanceList: '/admin/instance/list',
  createInstance: '/admin/instance/create',
  editInstance: '/admin/instance/',
  deleteInstance: '/admin/instance/',
  countryList: 'admin/country/list',
  updateCountry: '/admin/country/',
  setCountriesPhoneVerification: '/admin/country/phone-verification/disable',
  relatedEntity: '/admin/country/group/{id}/related-entity/list',

  instGroups: 'admin/country/group/list',

  //? Payments
  gatewayList: '/admin/gateway/list',
  createGateway: '/admin/gateway/',
  editGateway: '/admin/gateway/',
  deleteGateway: '/admin/gateway/',
  getPaymentMethod: '/admin/payment-method/',
  createPaymentMethod: '/admin/payment-method/',
  paymentMethodList: '/admin/payment-method/list',
  editPaymentMethod: '/admin/payment-method/',

  //? VAT
  vat: '/admin/vat',

  //? promoDiscount
  promoDiscount: '/admin/promo-discount',

  //? Settings
  createSetting: '/admin/setting',
  editSetting: '/admin/setting/',
  updateSettingValue: '/admin/setting/{id}/value',
  deleteSetting: '/admin/setting/',
  settingsList: '/admin/settings',

  //? Agreements Settings
  readAgreementsSettings: '/admin/agreement-settings',
  createAgreementsSetting: '/admin/agreement-settings',
  editAgreementsSetting: '/admin/agreement-settings',
  deleteAgreementsSetting: '/admin/agreement-settings',

  //? Services
  getServiceList: '/admin/service/list',
  createService: '/admin/service',
  getService: '/admin/service/{id}',
  updateServiceValue: '/admin/service/{id}',
  deleteService: '/admin/service/{id}',
  upsellingRulesServices: '/admin/upselling-rules/services',

  //? Coupons
  couponList: '/admin/coupon/list',
  createCoupon: '/admin/coupon/create',
  deleteCoupon: '/admin/coupon/',
  editCoupon: '/admin/coupon/',
  getCoupon: '/admin/coupon/',
  createCouponUsageRule: '/admin/coupon/{id}/usage-rule',
  deleteCouponUsageRule: '/admin/coupon/usage-rule/{id}',
  addRequiredPlanToCouponUsageRule: '/admin/coupon/usage-rule/{id}/required-service',
  updateRequiredPlanOfCouponUsageRule: '/admin/coupon/usage-rule/required-plan/{id}',
  deleteRequiredPlanOfCouponUsageRule: '/admin/coupon/usage-rule/required-plan/{id}',
  deleteRequiredServiceOfCouponUsageRule: '/admin/coupon/usage-rule/required-service/{id}',
  readCouponUsageRules: '/admin/coupon/{id}/usage-rules',

  //? Roles and Permissions
  adminRoles: '/admin/role/list',
  adminRole: '/admin/role/create',
  adminRoleDelete: '/admin/role/',
  adminRoleEdit: '/admin/role/',
  permissions: '/admin/permissions',
  permissionGroupCreate: '/admin/permission/group',
  permissionGroupUpdate: '/admin/permission/group/{id}',
  permissionGroupDelete: '/admin/permission/group/{id}',

  //? White List
  readWhiteList: '/admin/ip-whitelist',
  expandWhiteList: '/admin/ip-whitelist',
  deleteIpFromWhiteList: '/admin/ip-whitelist/',

  //? Recurring Types
  getRecurringTypes: '/admin/recurring-type',
  recurringTypes: '/admin/recurring-type',

  bundleGroup: '/admin/bundle-group',

  //? Customers Logs
  readCustomersLogs: '/admin/log/list',

  //? Countries Groups
  countriesGroupsCreate: '/admin/country/group/',
  countriesGroupsEdit: '/admin/country/group/',
  countriesGroupsDelete: '/admin/country/group/',

  //? Other
  uploadUrl: '/admin/picture/s3-upload-url',
  userMigrations: '/admin/report/user-migrations',
  userKycStatuses: '/admin/kyc/generate-report',
  uploadAttachmentsUrl: '/admin/attachment',
  uploadImageAttachmentsUrl: '/admin/attachment/images',
  uploadVideoAttachmentsUrl: '/admin/attachment/videos',
  deleteAttachment: '/admin/attachment/',

  //? KYC
  kycSettingsList: '/kyc/settings',
  editKycSettingsLevel: 'admin/kyc/settings/',
  kycSettingsLevel: 'admin/kyc/settings',
  listLevel1: '/admin/kyc/level1',
  editKycItem: '/admin/kyc/level1',
  listLevel2: '/admin/kyc/level2',
  editKycItemLevel2: '/admin/kyc/level2',
  assignKycManually: '/admin/kyc/start-manually',

  userDetails: '/admin/user/',

  productsActivations: '/admin/products-activations',
  verifyOrder: '/admin/order',
  conversionTemplatesBase: '/admin/conversion/templates/',
  payoutBase: '/admin/upload-payout/export/payout',

  //? Membership Rules
  membershipRulesList: '/v1/admin/upgrade-membership-rules',
  createMembershipRules: '/v1/admin/upgrade-membership-rules',
  editMembershipRules: '/v1/admin/upgrade-membership-rules/',
  deleteMembershipRules: '/v1/admin/upgrade-membership-rules/',
  membershipRule: '/v1/admin/upgrade-membership-rules/',

  //? Membership Level
  membershipLevelList: '/admin/membership-level',
  createMembershipLevel: '/admin/membership-level',
  editMembershipLevel: '/admin/membership-level/',
  deleteMembershipLevel: '/admin/membership-level/',
  membershipLevel: '/admin/membership-level/',

  membershipRegionalSettings: '/admin/membership-level/{id}/regional-membership-levels',
  membershipRegionalSetting: '/admin/membership-level/{id}/regional-membership-level/',

  //? Base Spending
  createBaseSpending: '/admin/base-spending/allocate',
  readBaseSpendingUserList: '/admin/base-spending/list/',

  //? Umustlook Items
  umustlookItemList: '/admin/umustlook-item',
  createUmustlookItem: '/admin/umustlook-item',
  editUmustlookItem: '/admin/umustlook-item/',
  deleteUmustlookItem: '/admin/umustlook-item/',

  //? FAQ Items
  faqItemList: '/admin/faq',
  planFaq: '/admin/plan-faq/',
  createFaqItem: '/admin/faq',
  editFaqItem: '/admin/faq/',
  deleteFaqItem: '/admin/faq/',

  //? Switch Subscription Rule
  switchSubscriptionRuleList: '/admin/switch-subscription-rule',
  createSwitchSubscriptionRule: '/admin/switch-subscription-rule/',
  editSwitchSubscriptionRule: '/admin/switch-subscription-rule/',
  deleteSwitchSubscriptionRule: '/admin/switch-subscription-rule/',

  //? Installments Orders

  installmentsOrders: '/admin/order/support-approval-requests',

  //? Company Info
  companyInfoList: '/admin/company-info/list',
  createCompanyInfo: '/admin/company-info',
  editCompanyInfo: '/admin/company-info/',
  deleteCompanyInfo: '/admin/company-info/',

  //? Currency Settings
  currencyConverterSettings: '/admin/currency-converter-setting',
  currencyConverterRates: '/admin/currency-rates/',

  //? Trigger Events
  triggerEventList: '/admin/trigger-ms/event/list',
  triggerEvent: '/admin/trigger-ms/event/',

  //? Manage User Bundles
  createSwitchPlan: '/admin/user-subscription/switch/base-plan',

  //? Mindhub Groups
  mindhubGroupList: '/admin/mindhub-group',
  createMindhubGroup: '/admin/mindhub-group',
  editMindhubGroup: '/admin/mindhub-group/',
  deleteMindhubGroup: '/admin/mindhub-group/',

  //? Notifications
  notificationsList: '/admin/shop-notification',
  createNotification: '/admin/shop-notification',
  editNotification: '/admin/shop-notification/',
  deleteNotification: '/admin/shop-notification/',

  //? Exchange rate
  exchangeRate: '/admin/points-exchange/list',
  createEditExchangeRate: '/admin/points-exchange',
};

export default urls;
