export enum SAGE_ROLES {
  TRIAL = 'trial subscription',
  BASIC = 'basic subscription',
  ADVANCED = 'advanced subscription',
  PRO = 'pro subscription',
  FOREX_BASIC = 'forex basic subscription',
  FOREX_ADVANCED = 'forex advanced subscription',
  CRYPTO_STARTER = 'crypto starter subscription',
  FOREX_PRO = 'forex pro subscription',
  CRYPTO_AI_BASKET = 'crypto ai basket',
  FX_PERSONAL_WEBHOOK = 'fx personal webhook',
}
